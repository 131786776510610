import { Injectable } from '@angular/core';
import { RequisitionApiProviderService, TimeSlotsApiProviderService } from '@project/data-providers';
import { UserProfileDataService } from '@project/services';
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable, of, race, Subject, throwError } from 'rxjs';
import {
  IRequisition,
  IRequisitionReason,
  ITimeSlot,
  ERequisitionFilterType,
  IRequisitionAnswerDTO,
} from '@project/view-models';
import { catchError, finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NotificationsService } from '@lib/notifications';
import { TranslateService } from '@project/translate';
import { EPatientSessionAction, IRequisitionWithParticipantAndAppointment } from './consts';
import { DateFormatter, everyMinute$, isArraysContentEqual, TGuid } from '@core/helpers';
import { ApplicationRuntimeService, BroadcastEventsService } from '@core/services';
import { CloseAsidePageEvent, ActionsManagerService, STATIC_DATE_FORMAT } from '@project/shared';
import { SocketMessagesDataProviderService } from '@project/data-providers';
import { RequisitionHelpersService, RequisitionIsUpdatedEventFeatureService } from '@project/services';
import { IModalComponentRef, ModalOverlayService } from '@lib/modal';
import { ReasonsComponent } from 'src/app/project/components/modal-reasons/modal-reasons.component';
import { ModalReasonsService } from 'src/app/project/components/modal-reasons/modal-reasons.service';
import { ApiService } from '@core/http';
import { environment } from '@env';
import { Schedule } from './schedule.interface';
import { EHowToProceed } from '../../questionnaire/services/questionnaire-manager.service';

@Injectable()
export class SessionsPatientManagerService extends ActionsManagerService<EPatientSessionAction> {
  protected readonly actionsHandlersMap = {
    [EPatientSessionAction.CloseRequisition]: (requisitionId: TGuid) => this.closeRequisition(requisitionId),
  };

  private readonly _requisitions$ = new BehaviorSubject<IRequisition[]>([]);
  private readonly _appointments$ = new BehaviorSubject<ITimeSlot[]>([]);
  public readonly appointments$ = this._appointments$.pipe();

  private modalRef: IModalComponentRef<ReasonsComponent>;

  public readonly allowDoctorRequest$: Observable<boolean> = this._requisitions$.pipe(
    map((requisitions) => requisitions.length === 0),
  );

  private readonly _appointmentsLiveIds$ = new BehaviorSubject<TGuid[]>([]);

  private readonly destroyed$ = new Subject();

  public readonly activeRequisitions$: Observable<IRequisitionWithParticipantAndAppointment[]> = combineLatest([
    this._requisitions$,
    this._appointments$,
    this._appointmentsLiveIds$,
  ]).pipe(
    map(([requisitions, appointments, liveAppointmentsIds]) => {
      const allAppointmentsRequisitionsIds: TGuid[] = appointments.map((item) => item.requisitionId);
      const liveAppointmentsRequisitionsIds: TGuid[] = appointments
        .filter((item) => liveAppointmentsIds.includes(item.id))
        .map((item) => item.requisitionId);

      // Requisition is live if it's appointment if live of it's not an appointment requisition at all
      return requisitions.filter(
        (item) =>
          liveAppointmentsRequisitionsIds.includes(item.id) || !allAppointmentsRequisitionsIds.includes(item.id),
      );
    }),
    map((requisitions) =>
      requisitions.map((req) => {
        const participant = this.requisitionHelpersService.getRequisitionParticipantForCurrentUser(req);
        const appointments = this._appointments$.value;

        let appointment: ITimeSlot | undefined;
        if (Array.isArray(appointments) && appointments.length > 0) {
          console.log('appointments', appointments);
          appointment = appointments?.find((a) => a.requisitionId === req.id);
        }

        return {
          ...req,
          participant,
          appointment,
        };
      }),
    ),
  );

  constructor(
    private requisitionApiProviderService: RequisitionApiProviderService,
    private userProfileDataService: UserProfileDataService,
    private timeSlotsApiProviderService: TimeSlotsApiProviderService,
    private notificationsService: NotificationsService,
    private requisitionIsUpdatedEventFeatureService: RequisitionIsUpdatedEventFeatureService,
    private requisitionHelpersService: RequisitionHelpersService,
    private broadcastEventsService: BroadcastEventsService,
    private socketMessagesProviderService: SocketMessagesDataProviderService,
    private applicationRuntimeService: ApplicationRuntimeService,
    private modalOverlayService: ModalOverlayService,
    private modalReasonsService: ModalReasonsService,
    private apiService: ApiService,
  ) {
    super();
  }

  init() {
    merge(
      this.requisitionIsUpdatedEventFeatureService.requisitionClosed$,
      this.requisitionIsUpdatedEventFeatureService.requisitionUpdated$,
    )
      .pipe(
        switchMap(() => forkJoin([this.loadRequisitions(), this.loadAppointments()])),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: () => {
          this.checkActiveAppointments(new Date());
        },
        error: () => {},
      });

    this.applicationRuntimeService.isStable$
      .pipe(
        first((isStable) => isStable),
        switchMap(() => everyMinute$({ fireInitial: true })),
        takeUntil(this.destroyed$),
      )
      .subscribe((date) => this.checkActiveAppointments(date));
  }

  destroy() {
    this.destroyed$.next();
  }

  loadRequisitions(): Observable<void> {
    return this.requisitionApiProviderService.getAll().pipe(
      catchError(() => {
        return of([]);
      }),
      tap((requisitions) => this._requisitions$.next(requisitions)),
      map(() => null),
    );
  }

  loadAppointments(): Observable<void> {
    const todayStaticDate = DateFormatter.dateToString(new Date(), { format: STATIC_DATE_FORMAT });
    return this.timeSlotsApiProviderService
      .getPatientAppointmentTimeSlots(todayStaticDate, ERequisitionFilterType.Active)
      .pipe(
        catchError(() => {
          this.notificationsService.error({
            message: TranslateService.localize('errors.unable-load-user-data'),
          });
          return of([]);
        }),
        tap((appointments) => this._appointments$.next(appointments)),
        map(() => null),
      );
  }

  private closeRequisition(requisitionId: TGuid) {
    this.setActionInProgress(EPatientSessionAction.CloseRequisition);

    this.requisitionApiProviderService
      .close(requisitionId)
      .pipe(finalize(() => this.clearActionInProgress()))
      .subscribe({
        error: (err) => {
          this.notificationsService.error({
            message: err?.error?.message || TranslateService.localize('nouns.error'),
          });
        },
      });

    this.modalRef = this.modalOverlayService.openOverlay<ReasonsComponent>(ReasonsComponent);
    this.modalRef.instance.headerTitle = TranslateService.localize('modals.reasons.title');

    this.modalRef.instance.submitReason$
      .pipe(takeUntil(race(this.destroyed$.asObservable(), this.modalRef.instance.close$)))
      .subscribe((reason: IRequisitionReason) => {
        reason.requisition_id = requisitionId;

        this.modalReasonsService.sendReason(reason).subscribe({
          complete: () => {
            this.modalRef.close();
          },
        });
      });
  }

  private checkActiveAppointments(currentDate: Date) {
    const appointments = this._appointments$.value;
    const shouldBeActiveAppointments = appointments.filter((a) => currentDate >= a.start);
    const newActiveAppointmentsIds: TGuid[] = shouldBeActiveAppointments.map((app) => app.id);
    const currentActiveAppointmentsIds: TGuid[] = this._appointmentsLiveIds$.value;

    if (!isArraysContentEqual(newActiveAppointmentsIds, currentActiveAppointmentsIds)) {
      this.broadcastEventsService.broadcast(new CloseAsidePageEvent());
      this._appointmentsLiveIds$.next(newActiveAppointmentsIds);
    }
  }

  getPatientSchedule(PatientId: string): Observable<Schedule[]> {
    return this.apiService
      .get<Schedule>(`${environment.environmentVariables.apiCoreUrl}/ExternalAppointment/Card/${PatientId}/Next`)
      .pipe(map((schedule: Schedule) => [schedule]));
  }

  public createRequisition(
    questionnaireId: TGuid,
    answers: IRequisitionAnswerDTO[],
    doctorSpecialization: number,
  ): Observable<any> {
    const payload = {
      questionnaireId,
      answers,
      doctor_specialization_id: doctorSpecialization,
    };

    const request$ = this.requisitionApiProviderService.createForLiveQueue(
      payload.questionnaireId,
      payload.answers,
      payload.doctor_specialization_id,
    );

    return request$.pipe(
      catchError((error) => {
        this.notificationsService.error({
          message: error?.error || TranslateService.localize('questionnaire.send.error.message'),
        });
        return throwError(error);
      }),
    );
  }

  public refreshActiveRequisitions(): void {
    forkJoin([this.loadRequisitions(), this.loadAppointments()]).subscribe({
      next: () => {
        this.checkActiveAppointments(new Date());
      },
      error: (err) => {
        console.error('Erro ao atualizar requisições ativas', err);
      },
    });
  }

  public closeRequisitionWithoutModal(requisitionId: TGuid): Observable<void> {
    this.setActionInProgress(EPatientSessionAction.CloseRequisition);

    return this.requisitionApiProviderService.close(requisitionId).pipe(
      finalize(() => this.clearActionInProgress()),
      catchError((err) => {
        this.notificationsService.error({
          message: err?.error?.message || TranslateService.localize('nouns.error'),
        });
        return throwError(err);
      }),
      map(() => null),
    );
  }
}
